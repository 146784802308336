import { createSharedComposable, useStorage as useVStorage } from '@vueuse/core'

export const useSidebar = createSharedComposable(() => {
  const menuOptions = computed(() => APP_NAVIGATION)
  const collapsed = useVStorage('menu-collapsed', false, localStorage)

  return {
    menuOptions,
    collapsed
  }
})
