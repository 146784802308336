import type { SidebarMenu } from '#shared/types/layout'

export const APP_NAVIGATION: SidebarMenu = [
  {
    label: 'navigation.dashboard',
    icon: 'mdi:home',
    route: 'dashboard'
  },
  {
    label: 'navigation.sell',
    accessKeys: ['CLIENT.SALES'],
    children: [
      {
        label: 'navigation.mySales',
        route: 'seller-items',
        accessKeys: ['CLIENT.SALES.ITEM']
      },
      {
        label: 'navigation.saleValidations',
        route: 'seller-sale-validations',
        accessKeys: ['CLIENT.SALES.BID'],
        condition: () => {
          const { $hasFlag } = useNuxtApp()
          return !$hasFlag('EP_AUCTELIA_UI')
        }
      },
      {
        label: 'navigation.questionsAnswers',
        route: 'seller-questions'
      },
      {
        label: 'navigation.salesInvoices',
        route: 'seller-invoices-sales'
      },
      {
        label: 'navigation.commissionInvoices',
        route: 'seller-invoices-commissions'
      }
    ]
  },
  {
    label: 'navigation.buy',
    accessKeys: ['CLIENT.PURCHASE'],
    children: [
      {
        label: 'navigation.myPurchases',
        route: 'buyer-items'
      },
      {
        label: 'navigation.questionsAnswers',
        route: 'buyer-questions'
      },
      {
        label: 'navigation.savedSearches',
        route: 'buyer-saved-searches'
      },
      {
        label: 'navigation.watchLists',
        route: 'buyer-watch-lists'
      },
      {
        label: 'navigation.purchaseInvoices',
        route: 'buyer-invoices-purchases'
      }
    ]
  },
  {
    label: 'navigation.profile',
    children: [
      {
        label: 'navigation.information',
        route: 'profile-information'
      },
      {
        label: 'navigation.notifications',
        route: 'profile-notifications'
      },
      {
        label: 'navigation.addresses',
        route: 'profile-addresses',
        condition: () => {
          const userStore = useUserStore()
          return userStore.activeOrganisation?.type === 'INDIVIDUAL'
        }

      },
      {
        label: 'navigation.documents',
        route: 'profile-documents'
      }
    ]
  },
  {
    label: 'navigation.organisation',
    condition: () => useUserStore().activeOrganisation?.type === 'COMPANY',
    accessKeys: ['CLIENT.ORGANISATION'],
    children: [
      {
        label: 'navigation.information',
        route: 'organisation-information',
        accessKeys: ['CLIENT.ORGANISATION.ORGANISATION']
      },
      {
        label: 'navigation.contracts',
        route: 'organisation-contracts',
        accessKeys: ['CLIENT.ORGANISATION.CONTRACT'],
        condition: () => {
          const { $hasFlag } = useNuxtApp()
          return !$hasFlag('EP_AUCTELIA_UI')
        }
      },
      {
        label: 'navigation.addresses',
        route: 'organisation-addresses',
        accessKeys: ['CLIENT.ORGANISATION.ADDRESS']
      },
      {
        label: 'navigation.documents',
        route: 'organisation-documents',
        accessKeys: ['CLIENT.ORGANISATION.ORGANISATION']
      },
      {
        label: 'navigation.users',
        route: 'organisation-users',
        accessKeys: ['CLIENT.ORGANISATION.USER'],
        condition: () => {
          const { $hasFlag } = useNuxtApp()
          return !$hasFlag('EP_AUCTELIA_UI')
        }
      }
    ]
  }
]
